
import { Options, Vue } from 'vue-class-component';

import * as yup from 'yup';

import NAICSCode from '@/common/NAICSCode.vue';

import IconService from '@/icons/Service.vue';

import requests from '@/requests';
import { NAICS } from '@/interface/other.interface';

import { footerLinks } from '@/settings';

@Options({
  components: {
    NAICSCode,
    IconService,
  },
})
export default class SignUp extends Vue {
  /* eslint-disable camelcase, max-len */
  public form = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: null,
    company_name: '',
    naics_code: '',
    password: '',
    termsConditions: false,
  }

  public formErrors: { [key: string]: string[] } = {
    first_name: [],
    last_name: [],
    email: [],
    phone_number: [],
    company_name: [],
    naics_code: [],
    password: [],
    termsConditions: [],
  }

  public loader = false;

  public footerLinks = footerLinks;

  // eslint-disable-next-line
  private regexp = /^.*[!()\[\]{}|\\`~!@#$%^&*_\-+=;:'\",<>.\/?].*$/;

  // eslint-disable-next-line
  private regexpSpecialSymbol = `()[]{}|\\`+ '`' +`~!@#$%^&*_-+=;:'\",<>./?`;

  private schema = yup.object().shape({
    first_name: yup.string().required('First name require fieldd').nullable(),
    last_name: yup.string().required('Last name required field').nullable(),
    email: yup.string().email('Email must be a valid').required('Email required field').nullable(),
    phone_number: yup.number().required('Phone number required field').nullable(),
    company_name: yup.string().required('Company name required field').nullable(),
    naics_code: yup.string().required('NAICS code required field').nullable(),
    password: yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/^.*(\w)(\D).*$/, 'This password is entirely numeric')
      .matches(this.regexp, `The password must contain at least 1 symbol: ${this.regexpSpecialSymbol}`)
      .nullable(),
    termsConditions: yup.boolean().required().isTrue('Read and agree Terms & Conditions'),
  });

  public signUp(): void {
    this.resetFormError();

    this.loader = true;

    this.schema.validate(this.form, { abortEarly: false })
      .then((res) => {
        requests.profile.signUp(res)
          .then((res) => {
            this.$store.commit('changeData', res.data.email);
            requests.profile.resendConfirmEmail(res.data.email);
            this.$router.push('/confirm-account?type=confirm');
          })
          .catch((err) => {
            if (err.response.data) {
              const err_list = Object.keys(err.response.data);
              err_list.forEach((e) => {
                if (this.formErrors[e]) {
                  this.formErrors[e as string].push(err.response.data[e]);
                }
              });
            }
          })
          .finally(() => {
            this.loader = true;
          });
      })
      .catch((err: yup.ValidationError) => {
        this.loader = false;
        err.inner.forEach((e) => {
          if (e.path && this.formErrors[e.path]) {
            this.formErrors[e.path as string].push(e.message);
          }
        });
      });
  }

  public handleNAICSTitle(naics: NAICS): void {
    this.form.naics_code = naics.code || '';
  }

  private resetFormError(): void {
    this.formErrors = {
      first_name: [],
      last_name: [],
      email: [],
      phone_number: [],
      company_name: [],
      naics_code: [],
      password: [],
      termsConditions: [],
    };
  }
}
